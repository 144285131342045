$(document).ready(function (e) {
    $('.branch_list').select2({
        placeholder: 'Select Branch'
      });

      // $('.locator-location').select2({
      //   placeholder: 'Select location'
      // });


});




// Package Inner Side Bar
$('body').on('click', '.details-v-nav-left li a.scroll-top-link', function (e) {
    e.preventDefault();
    var tagId = $(this).attr('name');
    $('.details-v-nav-left li a.scroll-top-link').removeClass('active');
    $(this).addClass('active');
    var box = document.querySelector('.details-scroll-y'),
        targetElm = document.querySelector('#' + tagId);
    scrollToElm(box, targetElm);
});


// Smooth Scroll functions

function scrollToElm(container, elm) {
    var pos = getRelativePos(elm);
    scrollTo(container, pos.top, 0.5);  // duration in seconds
}

function getRelativePos(elm) {
    var pPos = elm.parentNode.getBoundingClientRect(), // parent pos
        cPos = elm.getBoundingClientRect(), // target pos
        pos = {};

    pos.top = cPos.top - pPos.top + elm.parentNode.scrollTop,
        pos.right = cPos.right - pPos.right,
        pos.bottom = cPos.bottom - pPos.bottom,
        pos.left = cPos.left - pPos.left;

    return pos;
}

function scrollTo(element, to, duration, onDone) {
    var start = element.scrollTop,
        change = to - start,
        startTime = performance.now(),
        val, now, elapsed, t;

    function animateScroll() {
        now = performance.now();
        elapsed = (now - startTime) / 1000;
        t = (elapsed / duration);

        element.scrollTop = start + change * easeInOutQuad(t);

        if (t < 1)
            window.requestAnimationFrame(animateScroll);
        else
            onDone && onDone();
    };

    animateScroll();
}

function easeInOutQuad(t) {
    return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
};
